import React from "react";
import { v4 as uuidv4 } from "uuid";
import {
  SvgGithub,
  SvgLinkedin,
  SvgTelegram,
  SvgTwitter,
  SvgDiscord,
} from "../SVG";
import { SvgYoutube } from "../SVG/SvgYoutube";
import * as Styles from "./FloatingSocials.module.scss";

const SocialsList = [
  {
    label: "Discord",
    url: "https://discord.com/invite/omniaprotocol",
    icon: <SvgDiscord width="18" />,
    text: "Join Us!",
    top: 160,
  },
  {
    label: "Twitter-X",
    url: "https://twitter.com/omnia_protocol",
    icon: <SvgTwitter width="18" />,
    text: "Follow Us!",

    top: 215,
  },
  {
    label: "Telegram",
    url: "https://t.me/Omnia_protocol",
    icon: <SvgTelegram width="18" />,
    text: "Join Us!",
    top: 270,
  },
  {
    label: "Github",
    url: "https://github.com/omniaprotocol",
    icon: <SvgGithub width="18" />,
    text: "Follow Us!",
    top: 325,
  },
  {
    label: "Linkedin",
    url: "https://www.linkedin.com/company/protocol-omnia/",
    icon: <SvgLinkedin width="18" />,
    text: "Let's Connect!",
    top: 380,
  },
  {
    label: "Youtube",
    url: "https://www.youtube.com/@omniaprotocol",
    icon: <SvgYoutube width="22" />,
    text: "Subscribe!",
    top: 435,
  },
];

const FloatingSocials = () => {
  return (
    <>
      {SocialsList.map((item) => {
        const { url, icon, text, top } = item;
        return (
          <div class={Styles.floating} style={{ top: `${top}px` }}>
            <span class={Styles.icon}>{icon}</span>
            <a href={url} target="_blank" rel="noreferrer" key={uuidv4()}>
              {text}
            </a>
          </div>
        );
      })}
    </>
  );
};

export default FloatingSocials;
